import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Clientlogos from "../components/clientlogos/clientlogos"
import Footer from "../components/footer/footer"

import "./index.scss"
import "./about-us.scss"

import ImgAboutUs13 from "../images/about-us/about-us-13.jpg"
import ImgAboutUs14 from "../images/about-us/about-us-14.jpg"
import ImgAboutUs15 from "../images/about-us/about-us-15.jpg"
import ImgAboutUs16 from "../images/about-us/about-us-16.jpg"
import ImgAboutUs17 from "../images/about-us/about-us-17.jpg"
import ImgAboutUs20 from "../images/about-us/about-us-20.jpg"
import ImgAboutUs21 from "../images/about-us/about-us-21.jpg"
import ImgAboutUs23 from "../images/about-us/about-us-23.jpg"

import ImgAboutUsReference1 from "../images/client-logo/slsp.svg"
import ImgAboutUsReference2 from "../images/client-logo/peugeot.svg"
import ImgAboutUsReference3 from "../images/client-logo/innogy.svg"

import VideoBg from "../videos/ftrs-about-us.mp4"

const Aboutus = () => (

    <Layout>
        <SEO title="About us" description="We are a group of geeks with years of experience in creating digital products." />

        <div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content">

            <Row>
                <Col xs="12" lg="8" xl="6" className="pb-5">
                    <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Fully charged Future Rockstars</h1>

					<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Credibility</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">For more than 10&nbsp;years we have been creating web and&nbsp;mobile apps which are continuously used by&nbsp;market leading companies across multiple industries.</p>

                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Technological experience</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Throughout journey of&nbsp;creating various projects, we have solved many technological challenges and&nbsp;gained an&nbsp;overview that&nbsp;now helps us solve new challenges faster and&nbsp;in&nbsp;a&nbsp;consensual way.</p>

					<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Transparency</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Maintain complete control over what is happening during the&nbsp;development process thanks to&nbsp;our transparent communication tools, processes and&nbsp;real time reporting.</p>

                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">One Contact Person</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Get the personalized attention you&nbsp;need. With&nbsp;one contact person on&nbsp;our side, you'll always have a&nbsp;quick response to&nbsp;any question or&nbsp;request. Our&nbsp;flexible team of&nbsp;senior developers is ready to&nbsp;reschedule and&nbsp;scale delivery in&nbsp;the&nbsp;shortest time possible.</p>

                </Col>
                <Col xs="12" lg="4" xl="6" className="pb-5">
                    <div className="ftrs-sidepool">
                        <div className="ftrs-sidepool__container">
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs15} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs14} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs13} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs16} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs17} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs20} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs23} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs21} className="ftrs-sidepool__img" alt="" />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </Container>

        <Clientlogos></Clientlogos>

        <Container>
            <div className="ftrs-testimonials">
                <Row>
                    <Col xs="12" lg="8" xl="4" className="pb-5">
                        <div data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__signature">
                            <img src={ImgAboutUsReference1} className="ftrs-testimonials__img ftrs-testimonials__img--horizontal" alt="" />
                            <p className="ftrs-testimonials__name">Slovenska Sporitelna</p>
                            <p className="mt-0 mb-0">Member of Erste Group Bank AG</p>
                            <p className="mt-0 mb-0">Largest commercial bank in&nbsp;Slovakia</p>
                        </div>
						<p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text">"The main reasons why we decided to&nbsp;continue using feedTRACK in&nbsp;the&nbsp;following years are high questionnaire response rates, clear user interface and&nbsp;especially flexibility regarding data transfer (agency understands data and&nbsp;IT in&nbsp;general), structuring questionnaires and&nbsp;emails, task management and&nbsp;analysis.</p>
						<p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text">If we had to&nbsp;sum up in&nbsp;one word the&nbsp;reasons why feedTRACK won again, it would be professionalism."</p>
                    </Col>
                    <Col xs="12" lg="8" xl="4" className="pb-5">
                        <div data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__signature">
                            <img src={ImgAboutUsReference2} className="ftrs-testimonials__img ftrs-testimonials__img--vertical" alt="" />
                            <p className="ftrs-testimonials__name">P Automobil Import</p>
                            <p className="mt-0 mb-0">Official Peugeot Importer</p>
                        </div>
						<p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text">"We like the great user experience of&nbsp;AUTO&nbsp;eCIM and&nbsp;how intuitive and&nbsp;handy this online app is. Weekly updates give us a&nbsp;detailed overview of&nbsp;all the&nbsp;latest news from the automotive industry."</p>
                    </Col>
                    <Col xs="12" lg="8" xl="4" className="pb-5">
                        <div data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__signature">
                            <img src={ImgAboutUsReference3} className="ftrs-testimonials__img ftrs-testimonials__img--vertical" alt="" />
                            <p className="ftrs-testimonials__name">Innogy</p>
                            <p className="mt-0 mb-0">Member of E.ON</p>
                        </div>
						<p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text">"dMONITOR is a&nbsp;very useful tool. Thanks to&nbsp;dMONITOR we have a&nbsp;comprehensive overview about the&nbsp;direct communication activities on&nbsp;the&nbsp;Czech energy market.</p>
						<p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text">We value the smooth web app with&nbsp;so&nbsp;many filtering options in&nbsp;a&nbsp;data rich archive."</p>
                    </Col>
                </Row>

            </div>
        </Container>

		<Footer></Footer>

    </Layout>

)

export default Aboutus